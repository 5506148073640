<template>
  <span>{{ quantia }}</span>
</template>

<script>
import { computed } from "@vue/reactivity";

export default {
  name: "Dinheiro",
  props: {
    valor: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const quantia = computed(function () {
      const formatador = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatador.format(props.valor);
    });

    return { quantia };
  },
};
</script>
