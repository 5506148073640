<template>
  <div class="footer">
    <div class="texto">
      <strong v-if="loja.identificador">
        Loja gerada pelo
        <a :href="site" target="_blank">MEI GRÁTIS</a></strong
      >
      <br />
      Baixe o app e crie a sua loja gratuitamente!
    </div>
    <div class="logo">
      <a :href="site" target="_blank">
        <img src="@/assets/logo-meigratis.png" alt="MEI GRÁTIS" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Rodape",
  data() {
    return {};
  },
  methods: {},
  computed: {
    site() {
      const identificador = this.loja.identificador ?? "loja-desconhecida";
      return `https://meigratis.app.link/Fl01JYUcXmb?utm_source=loja&utm_medium=web&utm_campaign=${identificador}`;
    },
    ...mapState(["loja"]),
  },
};
</script>

<style scoped>
.footer {
  text-align: center;
}
.footer .texto {
  font-size: 12px;
}
.footer .texto a {
  color: #47b3c1;
  text-decoration: none;
}
.footer .texto a:hover {
  text-decoration: underline;
}
.footer .logo {
  margin-top: 8px;
}
</style>
